import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Image from "../components/image"
import { FaChevronRight } from "react-icons/fa"
import moment from 'moment'
import Helmet from "react-helmet"
import ListTop3 from "../components/listTop3"
import {ImageFilename} from '../utils/common'

const sizePage = ({ data, location, pageContext: { size_id } }) => {
	const size = data.sizesYaml
	const sizeStr = ((size.cid !== 'none') && (size.cid !== 'mizu') && (size.cid !== 'thick') && (size.cid !== 'toire') && (size.cid !== 'nyuu'))? 'サイズ' : 'タイプ' 
	const itemStr = ((size.cid !== 'none') && (size.cid !== 'mizu') && (size.cid !== 'thick') && (size.cid !== 'toire') && (size.cid !== 'nyuu'))? 'オムツ' : 'おしりふき' 
	let description = `${size.description}${sizeStr} ${itemStr}1枚あたりの最安値！`
	let preTitle = ``

  // allContentfulItemsを1枚当たりの価格が安い順に並び替える(Graphqlのソートだと100円以上の場合に並び替えが正しくできないため数値のソートを自前で行う)
	data.allContentfulItems.edges.sort(function(a,b){
		if((!a.node.itemList.length) && (!b.node.itemList.length)){
			return 0
		}
		// itemList無ければ後ろへ並べる
		else if((!a.node.itemList.length) && (b.node.itemList.length)){
			return 1
		}
		else if((a.node.itemList.length) && (!b.node.itemList.length)){
			return -1
		}
		return a.node.itemList[0].per - b.node.itemList[0].per
	})	

	// allTypesYamlを1枚当たりの価格が安い順に並び替える
	let orderAllTypesYaml = data.allTypesYaml.edges.map(edge => {
		const item = data.allContentfulItems.edges.find(n => {
			return n.node.sizeId === size.cid && n.node.typeId === edge.node.cid
		})
		if(item) {
			if(item.node.itemList.length){
				edge['per'] = item.node.itemList[0].per	
			}
		}
		return edge
	}).sort(function(a,b){
		return a.per - b.per
	})

	const cheap_item = data.allContentfulItems.edges.find(edge => {
		return edge.node.sizeId === size.cid
	})
	// 更新日付をGraphQLから直接表示するとUTCとなるため、自前のmoment.jsで表示
	const update = moment(cheap_item.node.updatedAt).format('YYYY年M月D日')

	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		},{
			"@type": "ListItem",
			"position": 2,
			"name": size.description,
			"item": "https://hula-la.com"+location.pathname
		}]
	};
	
	return (
  <Layout>
		<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>
			<ul>
				<li><Link to="/">トップ</Link></li>
				<li className="is-active"><Link to="" aria-current="page">{size.description}</Link></li>
			</ul>
		</nav>
			<>
				<Helmet>
     			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
   			</Helmet>
			 	<h2 id="size" className="title is-6" style={{marginTop: `0.2rem`, marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>{size.description}{sizeStr}</h2>
				<p style={{fontSize:`0.8rem`, color: `#808080`}}><small>&nbsp;&nbsp;&nbsp;<strong>割引後にポイントと送料を含めた実質価格で</strong>1枚あたりを計算！</small></p>
				<p style={{fontSize:`0.8rem`, color: `#808080`, marginBottom: `0.5rem`}}><small>&nbsp;&nbsp;&nbsp;（本ページのリンクには広告が含まれています）</small></p>
				<ListTop3 typeValue="all" sizeValue={size.cid} brandValue="all" shopValue="all" articleUrl={`https://hula-la.com/${size.cid}.html`} articleTitle={`${size.description}${sizeStr}`} />
				{orderAllTypesYaml.map(edge => {
					let cnt = 0
					const type = edge.node
					const item = data.allContentfulItems.edges.find(n => {
						return n.node.typeId === type.cid
					})
					if(!item) return <></>
					description += `${type.description}タイプ `
					// allBrandsYamlを1枚当たりの価格が安い順に並び替える
					let orderAllBrandsYaml = data.allBrandsYaml.edges.map(edge => {
						const item = data.allContentfulItems.edges.find(n => {
							return n.node.typeId === type.cid && n.node.brandId === edge.node.cid
						})
						if(item) {
							if(item.node.itemList.length){
								edge['per'] = item.node.itemList[0].per	
							}
						}
						return edge
					}).sort(function(a,b){
						return a.per - b.per
					})
					return (
						<>
						<div className="content" style={{marginTop: `1.3rem`}}>
							<h3 className="title is-6" style={{marginTop: `0.2rem`, marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>[{type.description}] 価格比較<small> ({update}更新)</small></h3>
							<div className="columns is-multiline is-gapless">
								{orderAllBrandsYaml.map(edge => {
			            const brand = edge.node
									const item = data.allContentfulItems.edges.find(n => {
										return n.node.typeId === type.cid && n.node.brandId === brand.cid && n.node.itemList.length !== 0
									})
									if(!item) return <></>
									if(!item.node.itemList.length) return <></>
									const filename = ImageFilename(brand.cid, type.cid, size.cid, item.node.subbrandId, item.node.itemList[0])
									description += `${brand.description}${item.node.itemList[0].per.toFixed(1)}円 `
									preTitle += preTitle === `` ? `${item.node.itemList[0].per.toFixed(1)}円/枚` : ``
									let most_min = 100
									let most_max = 0
									let most_unit = ''
									data.allSubbrandsYaml.edges.forEach(n => {
										if (n.node.brand_id === brand.cid) {
											n.node.types.forEach(m => {
												if(m.type_id === type.cid) {
													m.sizes.forEach(l => {
														if(l.size_id === size.cid) {
															most_min = l.min < most_min ? l.min : most_min
															most_max = l.max > most_max ? l.max : most_max
															most_unit = l.unit
														}
													})
												}
											})
										}
									})
									const min = most_min !== 0 ? `${most_min}${most_unit}` : ``
									const max = `${most_max}${most_unit}`
									let min_max = min + '～' + max
									if((most_min === 0) && (most_max === 0))
									{
										min_max = '-'
									}		
									cnt += 1
									const order = cnt
									return (
										<div className="column is-half">
											<Link to={`/${brand.cid}_${type.cid}_${size.cid}.html`}>
												<div className="card">
													<div className="card-content" style={{padding: `0.8rem`}}>
														<div className="media">
															<div className="media-left">
																<div>
																	<span>{order}</span><span style={{fontSize:`0.5rem`}}>位</span>
																</div>
																<Image filename={filename} alt={`${brand.description}${type.description}タイプ${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<div className="media-content">
																<div className="content">
																	<p>
																		<span style={{fontSize:`0.7rem`}}><strong>{brand.description}</strong></span>
																		<br />
																		<span style={{color: `#ff0000`}} className="is-size-4">&nbsp;{item.node.itemList[0].per.toFixed(1)}<span style={{fontSize:`0.7rem`}}> 円/枚</span> ～</span>
																		<br />
																		<span className="tag" style={{fontSize:`0.6rem`}}>{type.description}</span>
																		{ min_max !== '-' && (
																			<span className="tag" style={{fontSize:`0.6rem`}}>{min_max}</span>
																		)}
																	</p>
																</div>
															</div>
															<div className="media-right">
																<span className="icon" style={{height: `2.5em`}}>
																	<FaChevronRight />
																</span>
															</div>
														</div>
													</div>
												</div>
											</Link>
										</div>
									)
								})}
							</div>
						</div>
						<br></br>
						</>
					)
				})}
			</>
		<Seo title={`${size.description}${sizeStr}`} description={description} canonical={location.pathname} />
  </Layout>
	)
}

export const query = graphql`
	query($size_id: String!) {
		sizesYaml(cid: { eq: $size_id}) {
			cid
			description
		}
		allTypesYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allBrandsYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allContentfulItems(filter: {sizeId: { eq: $size_id}}) {
			edges {
				node {
					brandId
					typeId
					sizeId
					subbrandId
					itemList {
						num
						shopId
						shop
						href
						per
						per_org
						price
						price_org
						sendPrice
						souryou
						txt
						point
						star
						review
						reviewUrl
					}
				}
			}
		}
		allSubbrandsYaml {
			edges {
				node {
					brand_id
					subbrand_id
					description
					types {
						type_id 
						sizes {
							size_id
							min
							max
							unit
							num	
						}
					}
				}
			}
		}
	}
`

export default sizePage
